.SignUpForm {
  position:relative;
  background: #343f59;
  margin-top:5rem !important;
  color:#fff;
  font-size:1.1rem;
  padding-top:5rem !important;
  border-radius:2rem;

  p {
    small {
      font-size:.975rem;
    }
  }

  .smallPrint {
    font-size:.75rem
  }



  input,label {
    color:#212529;
    font-size:1rem;
  }

  h5 {
    font-size:1.4rem;
  }

  .Logo {
    height:100px;
    width:100px;
    border-radius:5rem;
    position:absolute;
    top: 0;
    left: 50%;
    background:white;
    transform: translate(-50%, -50%);
    background-image: url('/assets/logo-stacked.png');
    background-position: center;
    background-size: 75%;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-attachment: scroll;
    z-index:1;

  }


  .SignUpList {

    font-size:.925rem;

    padding:4rem 0 0;
    list-style: none;
    li {
      padding-bottom:.5rem;
    }

    li::before {
      content: "✅";
      font-size:1.25rem;
      display: inline-block;
      margin-right: 0.2rem;
    }


  }

}
