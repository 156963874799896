.Partners {
  background: #fff;
  padding: 1.5rem 0;

  h3 {
    text-align:center;
    margin-top: 1rem;
    margin-bottom:3.5rem;
  }

  .PartnerLogoContainer {
    display:flex;
    gap:20px;

    .PartnerItem {
      flex-basis: 20%;
      /* Additional styling for demonstration purposes */
      border: 1px solid black;
      padding: 20px;
    }

  }

  .PartnerLogos {
    filter: grayscale(100%);
    height:150px;
    object-fit: contain;
    margin: auto;
    display: block;
    transition: filter .4s;

    &:hover {
      filter:none;
    }

  }

}
