.Cta {
  position:relative;
  background: linear-gradient(0.14turn, #cee4f1, #66b2e4);
  border-color: rgb(209, 230, 250);
  border-radius: 2rem;
  border-width: 2px;
  box-shadow: 0 20px 20px rgba(216, 237, 248, .30);
  padding:80px;
  background-blend-mode: color-dodge;
  text-align: center;
  margin-top:2rem;

  &:hover {
    border-color: rgb(140, 193, 242);
    box-shadow: 0 0 #0000,0 0 #0000,0px 16px 40px rgba(140,193,242,.2);
  }

  p {
    font-weight:700
  }

  img {
    object-fit: fill;
    z-index: 0;
    height: 100%;
    inset: 0;
    -o-object-fit: cover;
    -o-object-position: center;
    object-position: center;
    position: absolute;
    width: 100%;
    max-width: 100%;
  }

  button {
    position:relative;
    z-index:10;
  }

}

.banner {
  background: #f1f1f1;
  //background:#66b2e4;
  padding: 3rem 0;
  //color:#fff;
  overflow: hidden;

  h4 {
    padding:0 3rem;
  }

  .bannerIcon {
    color:var(--brandBlue)
  }

}

.ServiceBlock
{
  background:#f1f1f1;
  border-radius:2rem;
  margin-top:2rem;
  padding:4rem;
  //color:#fff;

  .img {
    border-radius:2rem;
  }

  .logos {
    margin-top:20px;
    & > * {
      flex: auto;
      width: 30%;
      text-align:center;
      //height:300px;
      font-size:2rem;
      font-weight:bold;

      img {
        //max-width:100%;
        height:80px;

        &.anytimelogo {
          margin-top:15px;
          height:50px;
        }

      }
    }
  }

}

.Benefits {

  .BenefitsLogo
  {
    //height:150px;
    width:250px;
  }

}
