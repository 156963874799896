:root {
  --stepColor: rgb(66, 13, 176);
  --stepText: #fff;
  --stepPending: rgb(209,213,219);
}

.SignUpWizard {

  .Group {
    margin-bottom:3rem;
    width:100%;
    position:relative;


    h5 {

    }



  }

  .WebsiteSeller {

    list-style: none;
    padding: 0;
    margin: 0;
    font-size:.9rem;

    .Li {
      display:flex;
      margin-bottom:1rem
    }

    .Icon {
      display:flex;
      align-self: center;
      margin-right:1rem
    }

    .Text {
      display:flex;
      flex-direction: column;

      h5 {
        font-weight:bolder
      }

    }

  }




}