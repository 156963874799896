@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
:root {
  --bs-body-font-family: 'Montserrat', sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-color:#343f59;
  --brandPurple: rgb(167,156,191);
  --brandBlue: #66b2e4;
  --white: #FFFFFF;
  --bs-primary-rgb: 66, 13, 176;
  //--bs-success-rgb: 16,185,129;
  --success: #198754;
  --bs-border-radius: 2rem;
}

h2 {
  span {
    font-weight:700;
    color:#82a0b9;
  }
}

main {
  min-height:100vh;
  background:#fff;
  position:relative;
  z-index: 1;
  //max-width:100vw;
  overflow:hidden;
}

footer {
  //position:sticky;
  //position:fixed;
  bottom:0;
  left:0;
  background:#343f59;
}

.alert {
  border-radius: 0.5rem;
  --bs-alert-border-color: transparent;
  border: none;
  padding: 1rem;
  display:flex;
  font-weight:600;

  &.alert-with-title {
    font-weight:500;
  }

  .alert-title {
    font-weight:600;
  }

}

.alert-danger {
  --bs-alert-color: rgba(239,68,68);
  --bs-alert-bg: rgba(254,242,242);
  --bs-alert-border-color: transparent;
}

.alert-success {
  --bs-alert-color: rgba(16,185,129);
  --bs-alert-bg: rgba(236,253,245);
}

.btn-success {
/*  --bs-btn-hover-bg: rgb(233 64 53);
  --bs-btn-bg: rgb(237 107 98);
  --bs-btn-active-bg: rgb(237 107 98);
  --bs-btn-active-border-color: rgb(237 107 98);
  --bs-btn-disabled-bg: rgb(167 156 191);
  --bs-btn-disabled-border-color: rgb(167 156 191);
  --bs-btn-border-color:rgb(237 107 98);*/
}

.btn-primary {
  --bs-btn-hover-bg: rgb(233 64 53);
  --bs-btn-bg: rgb(237 107 98);
  --bs-btn-active-bg: rgb(237 107 98);
  --bs-btn-active-border-color: rgb(237 107 98);
  --bs-btn-disabled-bg: rgb(167 156 191);
  --bs-btn-disabled-border-color: rgb(167 156 191);
  --bs-btn-border-color:rgb(237 107 98);
}

.progressBtns {
  button {
    height:calc(3.5rem + 3px);
    font-size:1.2rem;
    text-transform:uppercase;
  }

  .btn-secondary {
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-bg: #6c757d;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
  }

}

.btn-secondary {
  --bs-btn-hover-bg: rgb(66, 13, 176);
  --bs-btn-bg: rgb(98, 52, 193);
  --bs-btn-active-bg: rgb(94 43 205);
  --bs-btn-active-border-color: rgb(94 43 205);
  --bs-btn-disabled-bg: rgb(167 156 191);
  --bs-btn-disabled-border-color: rgb(167 156 191);
}

.alert-info {
  --bs-alert-color: rgba(59,130,246);
  --bs-alert-bg: rgba(239,246,255);
}

.bg-primary {
  //--bs-alert-color: rgba(59,130,246);
  //--bs-primary-rgb: rgb(239,246,255);

}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(243,244,246,.9);

}


.form-switch .form-check-input {
  transform: scale(1.5);
  margin-left: -2em;
  margin-right: 1em;
}

.time-select {
  label.form-label {
    opacity: .65;
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem)
  }
}

input[type="radio"] {
  cursor:pointer;
}

input[type="radio"]:checked,
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked
{
  background-color: var(--success);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
  border-color: transparent;
}

.form-check.radio-input {
  border: 1px solid #ced4da;
  //padding: 1rem 0.75rem;
  border-radius: 0.375rem;
  padding-left: 0;
  height:100%;

  &:hover {
    border:1px solid rgb(66, 13, 176);
  }


  input {
    visibility: hidden;
    display:none;
  }

  &.hidden-label {
    display:flex;
    justify-content:center;

    label {
      display:flex;
      justify-content:center;
      align-items: center;
      flex-direction: column;

      img {
        //width:100%;
        max-width:75%;
      }

    }

  }

  &.radio-input-checked {
    border: 1px solid var(--success);
    //padding-left: calc(1.5em + 0.75rem);
    //&.hidden-label {
      padding-left:0;
    //}
    background-image: url('/assets/check-icon.svg');
    background-repeat: no-repeat;
    background-position: left calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  label {
    height:100%;
    width:100%;
    padding: 1rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
      text-align:center;
    }

    img {
      height:90px;
      padding-right:1rem;
      max-width:200px;

      @media only screen and (max-width: 768px) {
        width:100%;
        max-width: unset;
        object-fit: contain;
        margin-bottom:1rem;
      }

    }

  }

}

.form-check {

  .form-check-label {
    cursor:pointer;
  }

}

.rs-picker {
  width:100%;
}

.rs-picker-toggle {
  //border-color: red !important;
  border: 1px solid #ced4da!important;
  padding: .375rem 2.25rem .375rem .75rem;
  height:calc(3.5rem + 2px);
  line-height:1.25;
  width:100%;
  .rs-stack {
    height:100%;
  }
  .rs-stack-item {
    height: 100%;
    line-height: 1.25;
    /* padding-top: 0.75rem; */
    //justify-content: center;
    align-items: center;
    display: flex;
  }
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 18px;
}

  .input-suffix {
    position: absolute;
    right: 2rem;
    display: flex;
    top: 1rem;
  }
